import { type Language } from '@/features/language/types/language-types';
import { getUserBearerCookie } from '@/features/user/cookies/cookie-user-bearer';

export default async function createCookiesAccept(sessionId: string, locale: Language['locale']) {
  const bearerToken = getUserBearerCookie();

  const headers: HeadersInit = { 'Content-Type': 'application/json' };

  if (bearerToken) {
    headers.Authorization = `Bearer ${bearerToken}`;
  }

  const createCookiesAcceptResponse = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/v2/logging/elastic${bearerToken ? '' : '/anon'}`, {
    body: JSON.stringify({
      action_name: 'cookies_accepted',
      locale,
      session: sessionId,
    }),
    cache: 'no-store',
    headers,
    method: 'POST',
  });

  if (!createCookiesAcceptResponse.ok) {
    throw new Error('Failed to fetch data');
  }

  return createCookiesAcceptResponse.json();
}
