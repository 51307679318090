import {
  deleteCookie,
  getCookie,
  setCookie,
} from 'cookies-next/client';

const acceptedGDRPCookieName = 'acceptedGDPR';

export function getCookieAcceptedGDRP(): boolean {
  return !!getCookie(acceptedGDRPCookieName);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setCookieAcceptedGDRP(gdprData: any): void {
  setCookie(acceptedGDRPCookieName, JSON.stringify(gdprData));
}

export function deleteCookieAcceptedGDRP(): void {
  deleteCookie(acceptedGDRPCookieName);
}
