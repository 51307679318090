import { createNavigation } from 'next-intl/navigation';
import { defineRouting } from 'next-intl/routing';

import {
  DEFAULT_LANGUAGE,
  LOCALE_PREFIX,
  SUPPORTED_LOCALES,
} from '@/features/language/language-constants';

export const {
  Link,
  redirect,
  usePathname,
  useRouter,
} = createNavigation({
  defaultLocale: DEFAULT_LANGUAGE.locale, localePrefix: LOCALE_PREFIX, locales: SUPPORTED_LOCALES,
});

export const routing = defineRouting({
  alternateLinks: false,
  defaultLocale: DEFAULT_LANGUAGE.locale,
  localePrefix: LOCALE_PREFIX,
  locales: SUPPORTED_LOCALES,
});
