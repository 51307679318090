import styles from './loader.module.scss';

export default function Loader() {
  return (
    <div className={styles.loader}>
      <div className={styles.loader_item}>
        <div className={styles.loader_itemBubble} />
      </div>
      <div className={styles.loader_item}>
        <div className={styles.loader_itemBubble} />
      </div>
      <div className={styles.loader_item}>
        <div className={styles.loader_itemBubble} />
      </div>
    </div>
  );
}
