import { type CookieValueTypes } from 'cookies-next';
import {
  deleteCookie,
  getCookie,
  setCookie,
} from 'cookies-next/client';

const uuidCookieName = 'uuid';

export function getCookieUUID(): CookieValueTypes {
  return getCookie(uuidCookieName);
}

export function setCookieUUID(uuid: string): void {
  setCookie(uuidCookieName, uuid);
}

export function deleteCookieUUID(): void {
  deleteCookie(uuidCookieName);
}
