'use client';

import {
  useLocale,
  useTranslations,
} from 'next-intl';
import {
  useEffect,
  useState,
} from 'react';
import { useIsMounted } from 'usehooks-ts';
import { v4 as uuidv4 } from 'uuid';

import {
  getCookieAcceptedGDRP,
  setCookieAcceptedGDRP,
} from '@/layout/cookie-accept-popup/cookies/cookie-accepted-gdrp';
import {
  getCookieUUID,
  setCookieUUID,
} from '@/layout/cookie-accept-popup/cookies/cookie-uuid';
import createCookiesAccept from '@/layout/cookie-accept-popup/requests/create-cookies-accept';
import Button from '@/ui/button/button';

import styles from './cookie-accept-popup.module.scss';

export default function CookieAcceptPopup() {
  const translationsActions = useTranslations('global.actions');
  const translationsCookieAcceptPopup = useTranslations('layout.cookie-accept-popup');

  const isMounted = useIsMounted();
  const locale = useLocale();

  const [isShowCookiePopup,
    setIsShowCookiePopup] = useState(false);

  useEffect(() => {
    const isCookiesAccepted = getCookieAcceptedGDRP();
    const uuid = getCookieUUID();

    if (!uuid) {
      setCookieUUID(uuidv4());
    }

    setTimeout(() => { setIsShowCookiePopup(!isCookiesAccepted); }, 3000);
  }, [isMounted]);

  const handleAcceptClick = () => {
    let uuid = getCookieUUID();

    if (!uuid) {
      uuid = uuidv4();
      setCookieUUID(uuidv4());
    }

    createCookiesAccept(uuid, locale)
      .then((response) => {
        setCookieAcceptedGDRP(response);
        setIsShowCookiePopup(false);
      })
      .catch(() => {
        setIsShowCookiePopup(false);
      });
  };

  return isShowCookiePopup ? (
    <div className={styles.cookieAcceptPopup}>
      <div className={styles.cookieAcceptPopup_content}>
        <p className={styles.cookieAcceptPopup_title}>{translationsCookieAcceptPopup('title')}</p>

        <p className={styles.cookieAcceptPopup_text}>{translationsCookieAcceptPopup('text')}</p>

        <div className={styles.cookieAcceptPopup_accept}>
          <Button
            element="button"
            onClick={handleAcceptClick}
            fontSize="s"
            fullWidth
          >
            {translationsActions('accept')}
          </Button>
        </div>
      </div>
    </div>
  ) : null;
}
