import { type Language } from '@/features/language/types/language-types';

export const DEFAULT_LANGUAGE: Language = {
  direction: 'ltr',
  english_name: 'English (US)',
  locale: 'en-US',
  name: 'English (US)',
};

export const SUPPORTED_LOCALES: Language['locale'][] = [
  'ar-SA',
  'ca-ES',
  'cs-CZ',
  'da-DK',
  'de-DE',
  'el-GR',
  'en-AU',
  'en-CA',
  'en-IE',
  'en-NZ',
  'en-SG',
  'en-GB',
  'en-US',
  'en-ZA',
  'es-ES',
  'es-MX',
  'fil-PH',
  'fr-FR',
  'he-IL',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'nl-NL',
  'pl-PL',
  'pt-BR',
  'pt-PT',
  'ru-RU',
  'sv-SE',
  'tr-TR',
  'zh-CN',
  'zh-TW',
];

export const LOCALE_PREFIX = 'as-needed';
